import { toast } from 'react-toastify';

export const SuccessMessage = (message) => {
    toast.success(message,{autoClose:2500});
}
export const ErrorMessage = (message) => {
    toast.error(message);
}
export const WaringMessage = (message) => {
    toast.warning(message);
}