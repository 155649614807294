import { format } from 'date-fns'
import { SlideshowLightbox } from 'lightbox.js-react';
import React, { memo, useEffect, useRef, useState } from 'react'
import { Dropdown, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserAuth } from '../../auth/Auth';
import { PrivateAxios, PrivateAxiosFile, url } from '../../../environment/AxiosInstance';
import moment from 'moment';
import { formatDate } from '../../../environment/DateFormat';
import DeleteModel from '../../../component/DeleteModel';
import { SuccessMessage, WaringMessage } from '../../../environment/ToastMessage';
import { TaskMode } from '../../../utils/StaticData';


function CheckSheetDetails({ showChecksheetViewDetails, handleCloseChecksheetViewDetails, checkSheet, taskId }) {
  const { User, priority, status, Logout, mode } = UserAuth();
  const [users, setUsers] = useState([])
  const [doers, setDoers] = useState([])
  const [notify, setNotify] = useState([])
  const [update, setUpdate] = useState({
    'name': "",
    'assigned_by': '',
    'assign_to': '',
    'issue_date': '',
    'task_priority_id': '',
    'message': '',
    'notify_to': '',
    'auditor_id': '',
    'reminder_mode_id': '',
    'reminder_frequency': '',
    'file_is_require': '',
    'before_reminder': '',
    "status": "",
    "file": "",
    "task_mode_id": ""
  })
  const [assignee, setAssignee] = useState('')
  const [userDoers, setUserDoers] = useState('')
  const [NotifyUser, setNotifyUser] = useState('')
  const [description, setDescription] = useState('')
  const [attachFile, setAttachFile] = useState('')
  const [startDate, setStartDate] = useState('');
  const [name, setName] = useState('')
  const [subTask, setSubTask] = useState();

  useEffect(() => {
    if (checkSheet) {
      setUpdate({
        "id": checkSheet.id,
        'name': checkSheet.name,
        'assigned_by': checkSheet.assigned_by,
        'assign_to': checkSheet.assign_to,
        'issue_date': checkSheet.issue_date,
        'task_priority_id': checkSheet.task_priority_id,
        'message': checkSheet.message,
        'notify_to': checkSheet.notify_to,
        'reminder_mode_id': checkSheet.reminder_mode_id,
        'file_is_require': checkSheet.file_is_require,
        'status': checkSheet.status,
        'before_reminder': checkSheet.before_reminder,
        'task_mode_id': checkSheet.task_mode_id,
      });
      setUsers(User);
      setDoers(User);
      setNotify(User)
      // setAssignee(checkSheet && checkSheet.assignedByUser.name);
      // setUserDoers(checkSheet && checkSheet.assignedToUser.name);
      setDescription(checkSheet && checkSheet.message);
      setStartDate(checkSheet && checkSheet.issue_date)
      setName(checkSheet && checkSheet.name)
      setSubTask(checkSheet && checkSheet && checkSheet.subtask)
      setAttachFile(checkSheet && checkSheet && checkSheet.attach_file)
      // setNotifyUser(checkSheet && checkSheet.notifyTo && checkSheet.notifyTo.name)
    }

  }, [checkSheet != ''])

  //------------------------------------SubTask Add delete and update------------------------------
  const removeSubTask = (index) => {
    if (subTask.length === 1) {
      return;
    }
    const newProducts = subTask.filter((_, i) => i !== index);
    setSubTask(newProducts);
  };
  const addSubTask = () => {
    setSubTask([
      ...subTask,
      {
        name: "",

      },
    ]);
  }
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState('');
  const deleteModalClose = () => {
    setDeleteShow(false);
    setDeleteIndex('')
  }
  const deleteModalShow = (index) => {
    setDeleteShow(true);
    setDeleteIndex(index)
  }

  const handleSubtaskChange = (index, field, value) => {
    const newSubTask = [...subTask];
    newSubTask[index][field] = value;
    setSubTask(newSubTask);
  };
  const DeleteConfirm = () => {
    removeSubTask(deleteIndex)
    deleteModalClose()
  }
  const [subtaskDone, setSubtaskDone] = useState([])
  const GetDoneSubTask = () => {
    PrivateAxios.get(`checksheet/done-subtask/${taskId}`)
      .then((res) => {
        let subTask = [];
        if (res.data.data.length > 0) {
          res.data.data.map(item => subTask.push(item.sub_task_id))
        }
        setSubtaskDone(subTask);
      }).catch((err) => {
        if (err.response.status == 401) {
          Logout();
      }
      })
  }
  useEffect(() => {
    if (taskId) {
      GetDoneSubTask()
    }
  }, [taskId])



  const attachmentListAction = (
    <Popover id="statusChange" className="action-wrap">
      <div className="action-list">
        <div className="action-list-item">
          <i className="fi fi-sr-download me-2"></i>
          <span>Download</span>
        </div>
      </div>
    </Popover>
  );

  const clearAll = () => {
    setNotify(User)
    setAssignee('');
    setUserDoers('');
    setDescription('');
    setAttachFile('')
    setStartDate('')
    setName('')
    setSubTask('')
    setNotifyUser('')
  }

  return (
    <>
      <Modal show={showChecksheetViewDetails} onHide={() => { handleCloseChecksheetViewDetails(); clearAll() }} backdrop="static" centered size="xl" className="task-details-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="task-title-edit-wrap">
              <input value={update.name} className="task-name" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="task-details-modal-body-wrap">
          <div className="task-details-modal-body">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-user me-2"></i>
                      <span>Assignee</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown" >
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.assigned_by && User && User.map((item) => (
                          item.id == update.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-green">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        ))
                        }
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-user me-2"></i>
                      <span>Doer</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown" >
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.assign_to && User && User.map((item) => (
                          item.id == update.assign_to ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-yellow">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        ))
                        }
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-calendar me-2"></i>
                      <span>Planned Date</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown" >
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <span className="planned-date-txt">
                          {startDate != null ? moment(startDate).format("DD-MM-YYYY") : ""}
                        </span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-flag-alt me-2"></i>
                      <span>Priority</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.task_priority_id == 1 ?
                          <div className="priority-set urgent">
                            <i className="fi fi-sr-flag-alt mr-2"></i>
                            <span>Urgent</span>
                          </div> :
                          update.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                            <i className="fi fi-sr-flag-alt mr-2"></i>
                            <span>High</span>
                          </div> : update.task_priority_id == 3 ?
                            <div className="priority-set normal priority-list-item" >
                              <i className="fi fi-sr-flag-alt mr-2"></i>
                              <span>Normal</span>
                            </div> : <div className="priority-set low priority-list-item" >
                              <i className="fi fi-sr-flag-alt mr-2"></i>
                              <span>Low</span>
                            </div>
                        }
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item">
                      <i className="fi fi-rr-calendar me-2"></i>
                      <span>Mode</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <div className="status-item inprogress">
                          {TaskMode.map((item) => (
                            update.task_mode_id == item.id ?
                              <span> {item.title}</span> : ""
                          ))
                          }

                        </div>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-calendar me-2"></i>
                      <span>Start Date</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <span className="planned-date-txt">
                          {startDate != null ? moment(startDate).format("DD-MM-YYYY") : ""}
                        </span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">

              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-calendar me-2"></i>
                      <span>Complete Date</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <span className="planned-date-txt">
                          {startDate != null ? moment(startDate).format("DD-MM-YYYY") : ""}
                        </span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fi fi-rr-user me-2"></i>
                      <span>Notify To <br />(if not done)</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.notify_to != 0 ? User && User.map((item) => (
                          item.id == update.notify_to ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-green">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        )) : <div className="status-item todo">
                          <i className="fi fi-rr-user me-2"></i>
                          <span>Unset</span>
                        </div>
                        }
                        {/* <div className="profile-wrap">
                          {update.notify_to != null ?
                            <>
                              <div className="exp-avtar bg-exp-green">
                                {checkSheet && User.map((item) => (
                                  item.id == update.notify_to ?
                                    item.user_photo != null ?
                                      < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                      <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span> : ""

                                ))}
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap">{NotifyUser}</h5>
                              </div></> :
                            <div className="status-item todo">
                              <i className="fi fi-rr-user me-2"></i>
                              <span>Unset</span>
                            </div>
                          }
                        </div> */}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow-none border mt-3 overflow-hidden">
              <div className="card-header bg-exp-primary-grey-light-1 d-flex flex-wrap align-items-center justify-content-between">
                <h6 className="mb-0 me-3">Set Reminder</h6>
              </div>
              <div className="card-body pb-1">
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                    <div className='form-group'>
                      <label className='form-label'>Reminder Mode</label>
                      <div className='custom-select-wrap'>
                        <input type='text' value="Daily" className='form-control' readonly />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                    <div className='form-group'>
                      <label className='form-label'>Day Before </label>
                      <input type='number' readOnly className='form-control' value={update.before_reminder} placeholder='Day Before' onChange={(e) => setUpdate({ ...update, before_reminder: e.target.value })} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <hr className="my-3" />
            <div className='col-lg-12'>
              <div className='card shadow-none border overflow-hidden bg-light-blue'>
                <div className='card-body p-0'>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>

                          <th>Set Sub Task</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {subTask && subTask.map((task, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={task.name}
                                placeholder="Enter Subtask Name"
                                onChange={(e) =>
                                  handleSubtaskChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className='width-80'>
                              {taskId != '' ? subtaskDone.includes(task.id) ? <i class="fi fi-sr-check-circle gth-text-success fs-5"></i> : <i class="fi fi-rr-circle-dashed gth-text-info fs-5"></i> : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-3" />
            <div className="row">
              <div className="col-12">
                <div className="attachment-header">
                  <h6>Attachments</h6>
                </div>
              </div>
              <div className="col-12">
                <div className="card shadow-none border grid-files mt-2 mb-0">
                  <div className="card-header bg-exp-primary-grey-light-1">
                    <h6>Files</h6>
                  </div>
                  <div className="card-body">
                    <div className="gth-attachment-body">
                      {attachFile != null ? attachFile.split('.').pop() == "png" || attachFile.split('.').pop() == "jpg" || attachFile.split('.').pop() == "jpeg" ?
                        <figure className="gth-attachment-tile-item">
                          <div className="grid-action-btn">
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="auto"
                              overlay={attachmentListAction}
                            >
                              <button className="action-btn">
                                <i className="fi fi-br-menu-dots-vertical"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                          <div className="attachment-image">
                            <span className="related-icon"><i className="fi fi-rr-picture"></i></span>
                          </div>
                        </figure> : attachFile.split('.').pop() == "pdf" ?
                          <figure className="gth-attachment-tile-item">
                            <div className="grid-action-btn">
                              <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="auto"
                                overlay={attachmentListAction}
                              >
                                <button className="action-btn">
                                  <i className="fi fi-br-menu-dots-vertical"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                            <div className="attachment-image">
                              <span className="related-icon"><i className="fi fi-rr-file-pdf"></i></span>
                            </div>
                          </figure> : attachFile.split('.').pop() == "xls" || attachFile.split('.').pop() == "xlsx" ?
                            <figure className="gth-attachment-tile-item">
                              <div className="grid-action-btn">
                                <OverlayTrigger
                                  trigger="click"
                                  rootClose
                                  placement="auto"
                                  overlay={attachmentListAction}
                                >
                                  <button className="action-btn">
                                    <i className="fi fi-br-menu-dots-vertical"></i>
                                  </button>
                                </OverlayTrigger>
                              </div>
                              <div className="attachment-image">
                                <span className="related-icon"><i className="fi fi-rr-file-excel"></i></span>
                              </div>
                            </figure> : attachFile.split('.').pop() == "doc" ?
                              <figure figure className="gth-attachment-tile-item">
                                <div className="grid-action-btn">
                                  <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="auto"
                                    overlay={attachmentListAction}
                                  >
                                    <button className="action-btn">
                                      <i className="fi fi-rr-file-word"></i>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                <div className="attachment-image">
                                  <span className="related-icon"><i className="fi fi-rr-file-excel"></i></span>
                                </div>
                              </figure> : "" : ""
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-3" />
            <div className="description mb-3">
              <h6>Description</h6>
              <p>{checkSheet && checkSheet && checkSheet.message}</p>
            </div>
            <hr className="my-3" />

          </div>
        </Modal.Body>
      </Modal >
    </>
  )
}

export default CheckSheetDetails