import React from 'react'

function UnauthorizedPage() {
    return (
        <div>
            <h1>Unauthorized Access</h1>
            <p>You do not have permission to view this page.</p>
        </div>
    )
}

export default UnauthorizedPage
