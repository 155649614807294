import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PrivateAxios } from '../../environment/AxiosInstance'
import Loader from '../landing/loder/Loader';
import { SuccessMessage } from '../../environment/ToastMessage';
import moment from 'moment';
import { UserAuth } from '../auth/Auth';

function Permission() {
    const [loading, setLoading] = useState(false)
    const [permission, setPermission] = useState([])

    const GetAllPermission = async () => {
        setLoading(true)
        await PrivateAxios.get("all-permission")
            .then((res) => {
                setLoading(false)
                setPermission(res.data.data);

            }).catch((err) => {
                setLoading(false)
                console.log(err);
            })
    }

    useEffect(() => {
        GetAllPermission()
    }, [])

    return (
        <>
            {loading ? <Loader /> :
                <div className='p-4'>
                    <div className='card'>
                        <div className="card-header bg-primary-grey-light-2">
                            <h6 className="mb-0">Permissions</h6>
                        </div>
                        <div className='card-body role-permission-card'>
                            <div className='row'>
                                {/* <div className='col-12'>
                                    <div className='form-group mb-4'>
                                        <label className="custom-checkbox mb-0">
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                            <span className="text- text-dark">All</span>
                                        </label>
                                    </div>
                                </div> */}
                                {
                                    permission && permission.map((item, i) => (
                                        <div className='col-lg-4 col-md-6 col-sm-12'>
                                            <div className='form-group mb-4'>
                                                <label className="custom-checkbox mb-2">
                                                    {/* <input type="checkbox" />
                                                    <span className="checkmark" /> */}
                                                    <span className="text- text-dark">{item.name}</span>
                                                </label>
                                                <div className='ps-3'>
                                                    {
                                                        item.allmodule && item.allmodule.map((data) => (
                                                            <label className="custom-checkbox mb-2">
                                                                {/* <input type="checkbox" />
                                                                <span className="checkmark" /> */}
                                                                <span className="text-">{data.name}</span>
                                                            </label>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Permission