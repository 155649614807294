import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BaseUrl, PrivateAxios, url } from '../../environment/AxiosInstance'
import DataTable from 'react-data-table-component'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { SuccessMessage } from '../../environment/ToastMessage'
import moment from 'moment'

function CompanyManagement() {

    const [data, setData] = useState([])
    const GetCompany = () => {
        axios.get(`${BaseUrl}active-company`)
            .then((res) => {
                setData(res.data.data)

            }).catch((err) => {
                console.log(err);

            })
    }

    useEffect(() => {
        GetCompany()
    }, []);

    const StatusChange = (id, status) => {
        setData(prevent => prevent.map(step =>
            step.id === id ? { ...step, status: status ? 1 : 0 } : step
        ))
        PrivateAxios.post('update-status', { id: id, status: status })
            .then((res) => {
                SuccessMessage(res.data.msg)
            }).catch((err) => {

            })
    }

    const selectedColumns = [
        {
            name: 'Sl No.',
            selector: (row, index) => index + 1,
            minWidth: "80px",
            maxWidth: "80px"
        },
        {
            name: "Name",
            selector: (row) => row.company_name,
            sortable: true,
            width: "250px",
        },

        {
            name: "Email",
            selector: (row) => row.contact_email,
            sortable: true,
            reorder: true,
            width: "260px"
        },
        {
            name: "Phone Number",
            selector: (row) => row.company_phone,
            sortable: true,
            reorder: true,
            maxWidth: "180px"
        },
        {
            name: "GST NO.",
            selector: (row) => row.gst,
            sortable: true,
            reorder: true,
            width: "180px"
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            reorder: true,
            width: "100px",
            cell: (row) => (
                <div className='col-12'>
                    <div className='form-group'>
                        <label className="custom-switch" >
                            <input type="checkbox" name='is_require_file' checked={row.status == 1} onChange={(e) => StatusChange(row.id, e.target.checked)} />
                            <div className="switch-slider switch-round" />
                        </label>
                    </div>
                </div>
            )
        },
        {
            name: "Action",
            width: "100px",
            selector: (row) => '',
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => ShowModel(row)}>
                            <i className="fi fi-rr-eye"></i>
                        </button>
                    </OverlayTrigger>
                    {/* <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Edit
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" >
                            <i className="fi fi-rr-pencil"></i>
                        </button>
                    </OverlayTrigger> */}

                </div>
            ),
        },
    ];


    const [viewModel, setViewModel] = useState(false);
    const [viewModelData, setViewModelData] = useState('');
    const ShowModel = (data) => {
        setViewModel(true)
        setViewModelData(data)
    }
    const HideModel = () => {
        setViewModel(false);
        setViewModelData('')
    }



    return (
        <React.Fragment>
            <div className="p-4">
                <div className='card'>
                    <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                        <h3 className="card-title">Company List</h3>
                        {/* <button className="btn btn-exp-green ms-auto me-0">
                            <i className="fi fi-rr-user-add me-2"></i>Create User
                        </button> */}
                    </div>
                    <div className='card-body p-0'>
                        {!data.length > 0 ?
                            <div div className="w-100">
                                <div className="card bg-warning-light mb-0">
                                    <div className="card-body">
                                        <div className="exp-no-data-found text-exp-red">
                                            <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                            <h6>No Record Found</h6>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <>
                                <div className="table-view" >
                                    {/* <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                        <div className='table-button-group mb-3'>
                                            <button className='btn table-export-btn' onClick={generateExcel} >
                                                <img src={process.env.PUBLIC_URL + 'assets/images/file-csv.svg'} alt="icon" />
                                            </button>
                                            <button className='btn table-export-btn' onClick={generatePdf}>
                                                <img src={process.env.PUBLIC_URL + 'assets/images/file-pdf.svg'} alt="icon" />
                                            </button>
                                            <button className='btn table-export-btn' onClick={generatePrint}>
                                                <img src={process.env.PUBLIC_URL + 'assets/images/print.svg'} alt="icon" />
                                            </button>
                                        </div>
                                        <div className='d-flex align-items-center mb-3 ms-auto'>
                                            <label className='mr-2 mb-0'>Search: </label>
                                            <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                        </div>
                                    </div> */}

                                    <DataTable
                                        columns={selectedColumns}
                                        data={data}
                                        pagination={[5, 10, 25, 50]}
                                        theme="solarized"
                                        striped
                                        className='custom-table-wrap checklist-table-striped'
                                    //customStyles={customStyles}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Modal id="viewUserModal" show={viewModel} onHide={HideModel} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title className="gth-modal-title">
                        <h5 className="profile-name text-nowrap text-truncate">{viewModelData && viewModelData.company_name}</h5>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-1'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Company Name</label>
                                <p className="mb-0">{viewModelData && viewModelData.company_name}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Company Email</label>
                                <p className="mb-0">{viewModelData && viewModelData.company_email}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Company Phone</label>
                                <p className="mb-0">{viewModelData.company_phone ? `+${viewModelData && viewModelData.c_p_isd} ${viewModelData && viewModelData.company_phone}` : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Alternate Number</label>
                                <p className="mb-0">{viewModelData.company_alternate_phone ? `+${viewModelData && viewModelData.alternet_p_isd} ${viewModelData && viewModelData.company_alternate_phone}` : ""}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Address</label>
                                <p className="mb-0">{viewModelData && viewModelData.address} </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Contact Name</label>
                                <p className="mb-0">{viewModelData && viewModelData.contact_name} </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Contact Email</label>
                                <p className="mb-0">{viewModelData && viewModelData.contact_email} </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Contact phone</label>
                                <p className="mb-0">+{viewModelData && viewModelData.p_isd} {viewModelData && viewModelData.contact_phone}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Whatsapp Number</label>
                                <p className="mb-0">+{viewModelData && viewModelData.w_isd} {viewModelData && viewModelData.whatsapp_number} </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Start Date</label>
                                <p className="mb-0">{viewModelData && moment(viewModelData.start_date).format("DD-MMMM-YYYY")} </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Renew Type</label>
                                <p className="mb-0">{viewModelData && viewModelData.renew_type}</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label className="form-label">Renew Date</label>
                                <p className="mb-0">{viewModelData && moment(viewModelData.renew_date).format("DD-MMMM-YYYY")}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default CompanyManagement