import axios from 'axios';

// export const url = "http://15.207.108.222:5000/"
// export const url = "http://localhost:5000/"

export const url = "https://bms.gyanmartindia.in/api/uploads/"
export const BaseUrl = "https://bms.gyanmartindia.in/api/"

export const Axios = axios.create({
    baseURL: BaseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
})


export const PrivateAxios = axios.create({
    baseURL: BaseUrl,
})
PrivateAxios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['authentication'] = `${token}`;
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// PrivateAxios.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );


export const PrivateAxiosFile = axios.create({
    baseURL: BaseUrl,
})

PrivateAxiosFile.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['authentication'] = `${token}`;
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// PrivateAxiosFile.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
