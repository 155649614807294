import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function TaskBar() {
    const location = useLocation();
    return (
        <React.Fragment>
            <li>
                <Link to="/task" className={`gth-controller-view-item ${location.pathname == "/task" ? 'active' : ""}`}><i className="fi fi-rr-to-do"></i>Task Tracker</Link>
            </li>
            <li>
                <Link to="/checksheet" className={`gth-controller-view-item ${location.pathname == "/checksheet" ? 'active' : ""}`}><i className="fi fi-rr-to-do-alt me-2"></i>CheckSheet</Link>
            </li>
            <li>
                <Link to="/flow" className={`gth-controller-view-item ${location.pathname == "/flow" ? 'active' : ""}`}><i className="fi fi-rr-workflow"></i>Work Flow</Link>
            </li>
            {/* <li>
                <Link to="/dashboard-calendar" className={`gth-controller-view-item ${location.pathname == "/dashboard-calendar" ? 'active' : ""}`}><i className="fi fi-rr-calendar"></i>Calendar</Link>
            </li>
            <li>
                <Link to="/chart" className={`gth-controller-view-item ${location.pathname == "/chart" ? 'active' : ""}`}><i className="fi fi-rr-chart-histogram"></i>Chart</Link>
            </li> */}
        </React.Fragment>
    )
}

export default TaskBar