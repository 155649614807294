import React from 'react'
import { Modal } from 'react-bootstrap'

function DeleteModel({deleteShow,deleteModalClose,deleteAction}) {
  return (
    <Modal 
    show={deleteShow}
    onHide={deleteModalClose}
    backdrop="static"
    keyboard={false}
    centered
>
    <Modal.Header closeButton className="gth-light-red-bg">
        <Modal.Title className="gth-text-danger">Delete Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="delete-confirm-wrap text-center">
            <div className="delete-confirm-icon mb-3 mt-2">
                <img src={process.env.PUBLIC_URL + 'assets/images/delete-warning.svg'} alt="Warning" className="img-fluid" />
            </div>
            <h4 className="text-muted">Are you sure?</h4>
            <p className="text-muted">
                Do you really want to delete these record? This process cannot be undone.
            </p>
        </div>
    </Modal.Body>
    <Modal.Footer className='justify-content-center'>
        <button className='btn btn-secondary' onClick={deleteModalClose}>
            Cancel
        </button>
        <button className='btn btn-exp-red' onClick={deleteAction}>
            Delete
        </button>
    </Modal.Footer>
</Modal>
  )
}

export default DeleteModel